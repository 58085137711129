import React, { useEffect, useState } from 'react';
import classNameBind from 'classnames/bind';
import {
  Stack,
  Button,
  Badge,
  Preloader,
} from '@piwikpro/ui-components';
import styles from './Marketing.module.css';
import DEFAULT_MARKETING_SCREEN from './DefaultSettings';

const bindClasses = classNameBind.bind(styles);
interface UpdateMarketingScreenProps {
  header: string
  body: string
  imageUrl: string
  ctaText?: string
  ctaUrl?: string
  newBadgeVisibility?: boolean
}

export interface MarketingScreenProps {
  children: React.ReactNode
  doNotShowLoader?: boolean
}

const ALLOWED_SCREENS = ['CDP', 'MODULES', 'PRIVACY'];

const getDefaultSetting = screen => ({
  header: DEFAULT_MARKETING_SCREEN[screen].HEADER,
  body: DEFAULT_MARKETING_SCREEN[screen].BODY,
  imageUrl: DEFAULT_MARKETING_SCREEN[screen].IMAGE_URL,
  ctaText: DEFAULT_MARKETING_SCREEN[screen].CTA_TEXT,
  ctaUrl: DEFAULT_MARKETING_SCREEN[screen].CTA_URL,
  newBadgeVisibility: DEFAULT_MARKETING_SCREEN[screen].NEW_BADGE_VISIBILITY,
});

type DefaultScreens = 'CDP' | 'MODULES' | 'PRIVACY';

declare global {
  interface Window {
    updateMarketingScreen: (props: UpdateMarketingScreenProps | DefaultScreens) => void
  }
}

export const Marketing = ({ children, doNotShowLoader }: MarketingScreenProps) => {
  const [settings, setSettings] = useState<UpdateMarketingScreenProps>(getDefaultSetting('CDP'));
  const [isLoading, setIsLoading] = useState(true);
  const [delayTimer, setDelayTimer] = useState<null | ReturnType<typeof setTimeout>>(null);

  const resetDelayTimer = () => {
    setIsLoading(false);
    if (delayTimer) clearTimeout(delayTimer);
    setDelayTimer(null);
  };

  const updateMarketingScreen = (props: UpdateMarketingScreenProps | DefaultScreens) => {
    if (typeof props === 'string') {
      if (ALLOWED_SCREENS.includes(props)) {
        setSettings(getDefaultSetting(props));
      } else {
        // eslint-disable-next-line no-console
        console.warn(`Invalid value provided: ${props}. Allowed values are: ${ALLOWED_SCREENS.join(', ')}`);
      }
    } else {
      const { body, header, imageUrl } = props;
      if (body && header && imageUrl) {
        setSettings(props);
      }
    }
    resetDelayTimer();
  };
  window.updateMarketingScreen = updateMarketingScreen;

  useEffect(() => {
    if (isLoading && !delayTimer) {
      const timer = setTimeout(() => {
        setSettings(settings);
        resetDelayTimer();
      }, 2000);
      setDelayTimer(timer);
    }
  });


  if (isLoading) {
    return (
      <div id="MarketingView.Body" data-ppuc-id="MarketingView.Body">
        {!doNotShowLoader && <Preloader />}
      </div>
    );
  }

  return (
    <div className={bindClasses('container')}>
      <div className={bindClasses('content-wrapper')}>
        <div className={bindClasses('inner-wrapper')}>
          <div className={bindClasses('logo')}>
            <img alt="Logo" src="/statics/logo?v3" />
          </div>
          {children}
        </div>
      </div>
      <div className={bindClasses('marketing-wrapper')}>
        <div className={bindClasses('wrapper')}>
          <Stack spacing="extraWide" vertical fullHeight>
            <Stack.Item>
              <Stack spacing="narrow" vertical fullHeight>
                <Stack.Item>
                  <span id="MarketingView.NewBadge" data-ppuc-id="MarketingView.NewBadge" style={{ display: settings?.newBadgeVisibility ? 'block' : 'none' }}>
                    <Badge
                      text="NEW"
                      size="small"
                      color="blue"
                    />
                  </span>
                </Stack.Item>
                <Stack.Item>
                  <span
                    id="MarketingView.Header"
                    data-ppuc-id="MarketingView.Header"
                    className={bindClasses('header')}
                  >
                    {settings?.header}
                  </span>
                </Stack.Item>
                <Stack.Item>
                  <span
                    id="MarketingView.Body"
                    data-ppuc-id="MarketingView.Body"
                    className={bindClasses('body')}
                  >
                    {settings?.body}
                  </span>
                </Stack.Item>
                <Stack.Item>
                  {settings?.ctaText && settings?.ctaUrl && (
                    <Button
                      dataId="MarketingView.Button"
                      text={settings?.ctaText}
                      appearance="simple"
                      iconRight="arrow-right"
                      onClick={() => window.open(settings?.ctaUrl, '_blank')}
                    />
                  )}
                </Stack.Item>
              </Stack>
            </Stack.Item>
            <Stack.Item fill>
              <div
                className={bindClasses('image')}
                id="MarketingView.Image"
                data-ppuc-id="MarketingView.Image"
                style={{ backgroundImage: `url(${settings?.imageUrl})` }}
              />
            </Stack.Item>
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default Marketing;
