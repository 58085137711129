import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { TFunction } from 'react-i18next';
import { FormikProps } from 'formik';
import { useRouteMatch } from 'react-router';
import { History } from 'history';
import classNameBind from 'classnames/bind';
import {
  Layout,
  Stack,
  Form,
  TextFieldControl,
  Preloader,
  Text,
  Subtitle,
  Icon,
  Header,
  TextStyle,
  Information,
  withTranslation,
} from '@piwikpro/ui-components';
import productAnalytics from '@piwikpro/product-analytics';
import { FormValidator } from '@piwikpro/form-crate';
import { ConfigService, withInject } from '@piwikpro/platform';
import { useQuery } from '../../../hooks/useQuery';
import Errors from '../../Errors';
import { AstronAuthService } from '../../../services/AstronAuth';
import styles from './ResetForm.module.css';
import NoMarketingViewContainer from '../../../components/NoMarketingViewContainer';
import Marketing from '../../Marketing';
import { IAstronAuthState } from '../../../reducers';

const bindClasses = classNameBind.bind(styles);

const mapStateToProps = (state: IAstronAuthState) => ({
  isLoading: state.resetPasswordProcess.isLoading,
  failed: state.resetPasswordProcess.failed,
  failReason: state.resetPasswordProcess.failReason,
  passwordCode: state.resetPasswordProcess.passwordCode,
});

const connector = connect(mapStateToProps);

type ReduxProps = ConnectedProps<typeof connector>;

interface InjectedProps {
  astronAuthApi: AstronAuthService
  validation: FormValidator
  config: ConfigService
  history: History
}

interface Props extends ReduxProps, InjectedProps {
  t: TFunction
}

export interface IResetPasswordForm {
  password: string
  passwordRepeated: string
  setPasswordCode: string
  informationError?: string
}

const Success = ({ t }: { t: TFunction }) => (
  <Stack vertical horizontalAlignment="middle">
    <Stack.Item>
      <Icon size="large" color="green" name="checkmark-circle" />
    </Stack.Item>
    <Stack.Item>
      <Header type="small">
        {t('resetPassword.resetForm.successScreen.header')}
      </Header>
    </Stack.Item>
    <Stack.Item previousSpacing="narrow">
      <TextStyle>
        {t('resetPassword.resetForm.successScreen.text')}
      </TextStyle>
    </Stack.Item>
  </Stack>
);

export const ResetForm: React.FC<Props> = ({
  validation,
  isLoading,
  failReason,
  failed,
  passwordCode,
  history,
  astronAuthApi,
  config,
  t,
}) => {
  const [passwordSetWithSuccess, setPasswordSetWithSuccess] = useState(false);
  const { params } = useRouteMatch<{
    setPasswordCode?: string
  }>();
  const email = useQuery('product_analytics_email' || '');

  const checkPasswordCode = async () => {
    try {
      await astronAuthApi.checkResetPasswordCode({
        code: params.setPasswordCode || '',
      });
    // eslint-disable-next-line no-empty
    } catch {}
  };

  useEffect(() => {
    checkPasswordCode();
  }, []);

  const handleSubmit = async (values: IResetPasswordForm, {
    setSubmitting,
    setErrors,
  }: FormikProps<IResetPasswordForm>) => {
    try {
      productAnalytics.setUserContext(email);
      await astronAuthApi.resetPassword({
        code: passwordCode,
        password: values.password,
      });

      setPasswordSetWithSuccess(true);

      setTimeout(() => {
        // TODO: should we delay that for success screen
        // and which should be url of destination (analytics currently)
        window.location.assign(config.get('ACCOUNT_SETUP_INVITED_USER_REDIRECT_PATH'));
      }, 3000);
    } catch (err: any) {
      if (err.name === 'TooManyRequestsError') {
        setErrors({ informationError: t('general.notifications.errors.rateLimit.text') });
      } else {
        setErrors({
          ...err.errors,
          informationError: t('general.notifications.errors.generic.text'),
        });
      }
    }

    setSubmitting(false);
  };

  if (failed) {
    return (
      <NoMarketingViewContainer>
        <Errors
          viewType="inviteOrResetUserSetupPassword"
          reason={failReason}
          history={history}
        />
      </NoMarketingViewContainer>
    );
  }

  if (isLoading) {
    return (
      <Preloader />
    );
  }

  return (
    <Marketing>
      <Stack vertical horizontalAlignment="middle">
        <Stack.Item>
          <Layout maxWidth="narrow">
            <Layout.Column>
              <div className={bindClasses('flex-wrapper')}>
                <div className={bindClasses('width-wrapper')}>
                  {passwordSetWithSuccess && <Success t={t} />}
                  {!isLoading && !failReason && !passwordSetWithSuccess && (
                    <Form
                      id="user-reset-password-form"
                      initialValues={{
                        password: '',
                        passwordRepeated: '',
                      } as IResetPasswordForm}
                      validationSchema={validation.createValidationSchema((validators: any) => ({
                        password: validators.users.password,
                        passwordRepeated: validators.users.passwordRepeated,
                      }))}
                      onSubmit={handleSubmit}
                      render={({
                        setFieldValue, validateForm, setFieldTouched, values, errors,
                      }: FormikProps<IResetPasswordForm>) => (
                        <Stack vertical>
                          <Stack.Item fill>
                            <Subtitle>
                              {t('resetPassword.resetForm.header.text')}
                            </Subtitle>
                          </Stack.Item>
                          <Stack.Item>
                            <Text>
                              {t('resetPassword.resetForm.description.text')}
                            </Text>
                          </Stack.Item>
                          {errors.informationError && (
                            <Stack.Item fill>
                              <Information type="error">
                                {errors.informationError}
                              </Information>
                            </Stack.Item>
                          )}
                          <Stack.Item fill>
                            <TextFieldControl
                              name="password"
                              type="password"
                              label={t('resetPassword.resetForm.passwordInput.label')}
                              info={t('resetPassword.resetForm.passwordInput.info')}
                              required
                              onChange={(e: {target: { name: string, value: string }}) => {
                                setFieldValue('password', e.target.value);
                                setFieldTouched('password', true);
                              }}
                              onBlur={() => validateForm(values)}
                              autoFocus
                            />
                          </Stack.Item>
                          <Stack.Item fill>
                            <TextFieldControl
                              name="passwordRepeated"
                              type="password"
                              label={t('resetPassword.resetForm.passwordRepeat.label')}
                              onChange={(e: {target: { name: string, value: string }}) => {
                                setFieldValue('passwordRepeated', e.target.value);
                                setFieldTouched('passwordRepeated', true);
                              }}
                              onBlur={() => validateForm(values)}
                              required
                            />
                          </Stack.Item>
                          <Stack.Item fill>
                            <Form.Submit fullWidth text={t('resetPassword.resetForm.confirmBtn.text')} />
                          </Stack.Item>
                        </Stack>
                      )}
                    />
                  )}
                </div>
              </div>
            </Layout.Column>
          </Layout>
        </Stack.Item>
      </Stack>
    </Marketing>
  );
};

export default connector(withInject<InjectedProps>({
  astronAuthApi: 'AstronAuthCrate.astronAuth',
  validation: 'FormCrate.validation',
  config: 'config',
  history: 'RouterCrate.history',
})(
  withTranslation(['astronauth'])(ResetForm),
));
